import React from "react";
import SignInFields from "../components/SignInFields";
import { GetAccountInfo, RedirectToQueryParam } from "../utils/account";

const SignInScreen = () => {
	// Check if already signed in. If so, redirect to the main screen.
	const account = GetAccountInfo()
	if (account?.id.length) {
		RedirectToQueryParam();
		return;
	}

	return (
		<div className="w-full min-h-full relative bg-neutral-80 flex flex-row flex-wrap gap-7 sm:gap-20 items-center justify-center p-7 box-border text-base text-adventure-blue-adventure-blue-500 font-text-l-medium">
			<div className="sm:w-[200px] flex flex-col items-start justify-start box-border">
				<div className="self-stretch flex flex-col items-center justify-center gap-[1px]">
					<img
						className="w-[70px] sm:w-[100px] max-w-full"
						loading="lazy"
						alt=""
						src="/logo--guzo-2@2x.png"
					/>
					<img
						className="w-[100px] sm:w-[150px] max-w-full"
						loading="lazy"
						alt=""
						src="/guzo-1-11@2x.png"
					/>
				</div>
			</div>
			<div className="max-w-full w-[450px] rounded-xs-8 bg-neutral-10 box-border flex flex-col items-center justify-start py-[50px] px-5 gap-7 text-center text-9xl-3 text-black border-[1px] border-solid border-mediumslateblue">
				<div className="font-semibold">
					Sign in to your Guzo account.
				</div>
				<SignInFields />
			</div>
		</div>
	);
};

export default SignInScreen;
