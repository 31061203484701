import { React, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import { accountTypeState } from "../recoil/state";
import { authApi } from "../api/auth";
import { RedirectToQueryParam } from "../utils/account";
import AlertToast from "./alertToast";

const SignUpFields = () => {
	const [_currentUser, setCurrentUser] = useRecoilState(accountTypeState);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loader, setLoader] = useState(false);

	const trigger = useRef()

	const onButtonClick = async () => {
		try {
			setLoader(true);
			const user = await authApi.register(email, password, firstName, lastName);

			setLoader(false);
			localStorage.clear();
			localStorage.setItem("account", JSON.stringify(user));
			setCurrentUser(user);
			// get redirect from url and redirect there or redirect to the main screen.
			user?.id.length > 0 && RedirectToQueryParam();

			console.log(user, "user");
			setLoader(false);
		} catch (e) {
			// TODO use real logging
			console.log(e);
			setLoader(false);

			let err = e?.message.split(": ").pop(); // remove the "FirebaseError: Firebase: "
			const errorMsgs = {
				"Error (auth/email-already-in-use).": "That email is already in use.",
				"Error (auth/invalid-email).": "That's not a valid email.",
				"Password should be at least 6 characters (auth/weak-password).'": "Password should be at least 6 characters.",
			}

			let errorText = (err in errorMsgs) ? errorMsgs[err] : err;
			trigger.current.trigger(errorText)
		}
	};

	// 697 -> 800
	// 333.5 -> 370
	// gap-[-30] -> 50

	return (
		<div className="flex flex-col py-10 gap-5 max-w-full">
			<AlertToast type="error" ref={trigger} />
			<div className="self-stretch flex flex-col items-center justify-start gap-[20px] max-w-full">
				<div className="name-container flex flex-row flex-wrap items-start gap-5 max-w-full">
					<div className="first-name-field rounded-3xs max-w-full grow flex flex-col items-start justify-center gap-[4px]">
						<div className="self-stretch h-[18px] relative text-xs leading-[18px] font-medium font-text-l-medium text-power-black-power-black-300 text-left inline-block">
							First Name
						</div>
						<input
							className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100"
							placeholder="First Name"
							type="text"
							value={firstName}
							onChange={(event) => setFirstName(event.target.value)}
						/>
					</div>
					<div className="last-name-field rounded-3xs max-w-full grow flex flex-col items-start justify-center gap-[4px]">
						<div className="self-stretch h-[18px] relative text-xs leading-[18px] font-medium font-text-l-medium text-power-black-power-black-300 text-left inline-block">
							Last Name
						</div>
						<input
							className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100"
							placeholder="Last Name"
							type="text"
							value={lastName}
							onChange={(event) => setLastName(event.target.value)}
						/>
					</div>
				</div>
				<div className="self-stretch rounded-3xs flex flex-col items-start justify-center gap-[4px]">
					<div className="self-stretch h-[18px] relative text-xs leading-[18px] font-medium font-text-l-medium text-power-black-power-black-300 text-left inline-block">
						Email
					</div>
					<input
						className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100"
						placeholder="Email"
						type="text"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
					/>
				</div>
				<div className="self-stretch rounded-3xs flex flex-col items-start justify-center gap-[4px]">
					<div className="self-stretch h-[18px] relative text-xs leading-[18px] font-medium font-text-l-medium text-power-black-power-black-300 text-left inline-block">
						Password
					</div>
					<input
						className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100"
						placeholder="Password"
						type="password"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
					/>
				</div>
			</div>
			<div className="self-stretch box-border flex flex-col items-center justify-start gap-[17px]">
				<div className="relative text-xs leading-[18px] font-text-l-medium text-center inline-block">
					<span className="text-black">{`By signing up, you agree to `}</span>
					<a
						className="text-adventure-blue-adventure-blue-5001"
						href="/terms-of-service"
					>
						Terms of Service
					</a>
					<span className="text-black">{`, and `}</span>
					<a
						className="text-adventure-blue-adventure-blue-5001"
						href="/privacy-policy"
					>
						Privacy Policy
					</a>
					<span className="text-black">{`, and `}</span>
					<a
						href="/cookie-policy"
						className="text-adventure-blue-adventure-blue-5001"
					>
						Cookie Policy
					</a>
					<span className="text-gray-300">.</span>
				</div>
				{!loader ? (
					<button
						className="cursor-pointer [border:none] py-2 px-5 bg-adventure-blue-adventure-blue-50 w-[200px] rounded-3xl flex flex-row items-center justify-center box-border gap-[4px]"
						onClick={onButtonClick}
					>
						<div className="h-6 w-[95px] relative text-base tracking-[-0.01em] leading-[24px] font-medium font-text-l-medium text-adventure-blue-adventure-blue-500 text-left inline-block">{`Agree & Join`}</div>
					</button>
				) : (
					<div className="">
						<ClipLoader color="blue" loading={true} />
					</div>
				)}
			</div>
			<div className="flex flex-col items-center justify-start py-[30px] px-0 box-border">
				<div className="self-stretch flex flex-col items-center justify-center">
					<div className="h-6 relative text-base tracking-[-0.01em] leading-[24px] font-semibold font-text-l-medium text-center inline-block">
						<span className="text-black">{`Already have a Guzo account? `}</span>
						<a
							className="text-adventure-blue-adventure-blue-500"
							href="/sign-in"
						>
							Sign in
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUpFields;
