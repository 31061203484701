import { React, useRef, useState } from "react";
import { authApi } from "../api/auth";
import { ClipLoader } from "react-spinners";
import { accountTypeState } from "../recoil/state";
import { useRecoilState } from "recoil";
import { RedirectToQueryParam } from "../utils/account";
import AlertToast from "./alertToast";

const SignInFields = () => {
	const [_currentUser, setCurrentUser] = useRecoilState(accountTypeState);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loader, setLoader] = useState(false);

	const trigger = useRef()

	const onButtonClick = async () => {
		try {
			setLoader(true);
			const user = await authApi.login(email, password);

			setLoader(false);
			localStorage.clear();
			localStorage.setItem("account", JSON.stringify(user));
			setCurrentUser(user);
			// get redirect from url and redirect there or redirect to the main screen.
			user?.id.length > 0 && RedirectToQueryParam();

			setLoader(false);
		} catch (e) {
			// TODO use real logging
			console.log(e);
			setLoader(false);

			let err = e?.message.split(": ").pop(); // remove the "FirebaseError: Firebase: "
			const errorMsgs = {
				"Error (auth/invalid-credential).": "Your email or password is incorrect. Please double-check your input and try again.",
				"Error (auth/invalid-email).": "That's not a valid email.",
			}

			let errorText = (err in errorMsgs) ? errorMsgs[err] : err;
			trigger.current.trigger(errorText)
		}
	};

	return (
		<div className="w-full max-w-[280px] flex flex-col items-start justify-start gap-5">
			<AlertToast type="error" ref={trigger} />
			<div className="self-stretch rounded-3xs flex flex-col items-start justify-start gap-[4px]">
				<div className="self-stretch h-[18px] relative text-xs leading-[18px] font-medium font-text-l-medium text-power-black-power-black-300 text-left inline-block">
					Email
				</div>
				<input
					className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-start justify-start p-1.5 border-[1px] border-solid border-neutral-100"
					placeholder="Email"
					type="text"
					value={email}
					onChange={(event) => setEmail(event.target.value)}
				/>
			</div>
			<div className="self-stretch rounded-3xs flex flex-col items-start justify-start gap-[4px]">
				<div className="self-stretch h-[18px] relative text-xs leading-[18px] font-medium font-text-l-medium text-power-black-power-black-300 text-left inline-block">
					Password
				</div>
				<input
					className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-start justify-start p-1.5 border-[1px] border-solid border-neutral-100"
					placeholder="Password"
					type="password"
					value={password}
					onChange={(event) => setPassword(event.target.value)}
				/>
			</div>
			<div className="self-stretch pb-5">
				{!loader ? (
					<button
						className="cursor-pointer py-2 px-10 bg-adventure-blue-adventure-blue-50 font-medium text-base text-adventure-blue-adventure-blue-500 rounded-3xl"
						onClick={onButtonClick}
					>Sign in</button>
				) : (
					<div className="flex w-full justify-center">
						<ClipLoader color="blue" loading={true} />
					</div>
				)}
			</div>

			<div className="self-stretch flex flex-col items-center justify-start">
				<div className="text-base font-semibold font-text-l-medium text-black text-center pb-3">Don’t have an account?</div>
				<a
					className="cursor-pointer py-2 px-10 bg-gray-200 rounded-3xl text-base font-medium text-neutral-10"
					href="/signup"
				>Sign up</a>
			</div>
		</div >
	);
};

export default SignInFields;
