import React from "react";

const CookiePolicy = () => {
	return (
		<article>
			<a className="m-5 text-base font-text-l-medium" href="/signup">
				Back
			</a>
			<h1 className="m-5 text-5xl font-text-l-medium flex justify-left">
				Cookie Policy
			</h1>
			<p className="m-5 text-base font-text-l-medium">
				This Cookie Policy explains how [Company Name] (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;)
				uses cookies and similar tracking technologies (collectively, &quot;Cookies&quot;)
				on our website [Website URL] (the &quot;Site&quot;) and in our mobile application
				(the &quot;App&quot;) (collectively, the &quot;Services&quot;). It&apos;s important that you read
				this Cookie Policy so you can understand what type of Cookies we use,
				how we use them, and your choices regarding Cookies.
			</p>

			<h2 className="m-5 text-xl font-text-l-medium">What are Cookies?</h2>
			<p className="m-5 text-base font-text-l-medium">
				Cookies are small data files that are placed on your computer or mobile
				device when you visit a website. Cookies serve various functions, such
				as remembering your preferences, analyzing your use of the website, and
				providing tailored content and advertising.
			</p>

			<h2 className="m-5 text-xl font-text-l-medium">
				Types of Cookies We Use
			</h2>
			<p className="m-5 text-base font-text-l-medium">
				We use the following types of Cookies on our Services:
			</p>

			<h3 className="m-5 text-l font-text-l-medium">1. Necessary Cookies</h3>
			<p className="m-5 text-base font-text-l-medium">
				These Cookies are essential for the proper functioning of our Services.
				They allow you to navigate the Site and use its features. Without these
				Cookies, certain features and services may not work properly.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">
				2. Analytical/Performance Cookies
			</h3>
			<p className="m-5 text-base font-text-l-medium">
				These Cookies collect information about how you use our Services, such
				as which pages you visit and how long you spend on each page. This helps
				us improve the performance of our Services.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">3. Functional Cookies</h3>
			<p className="m-5 text-base font-text-l-medium">
				These Cookies allow our Services to remember choices you make (such as
				your language or the region you&apos;re in) and provide enhanced, more
				personalized features.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">
				4. Targeting/Advertising Cookies
			</h3>
			<p className="m-5 text-base font-text-l-medium">
				These Cookies are used to deliver content and advertisements that are
				more relevant to you and your interests. They may be used to show you
				relevant ads on our Services or third-party websites.
			</p>

			<h2 className=" m-5 text-xl font-text-l-medium">How We Use Cookies</h2>
			<p className="m-5 text-base font-text-l-medium">We use Cookies to:</p>
			<ul>
				<li className="text-base font-text-l-medium">
					Provide and improve our Services
				</li>
				<li className="text-base font-text-l-medium">
					Understand and save your preferences for future visits
				</li>
				<li className="text-base font-text-l-medium">
					Compile aggregate data about site traffic and site interactions to
					offer better site experiences and tools in the future
				</li>
				<li className="text-base font-text-l-medium">
					Serve targeted advertisements
				</li>
			</ul>

			<h2 className=" m-5 text-xl font-text-l-medium">
				Your Choices Regarding Cookies
			</h2>
			<p className="m-5 text-base font-text-l-medium">
				You can control and manage Cookies in various ways. Please keep in mind
				that removing or blocking Cookies can affect the functionality of our
				Services and your user experience.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">1. Browser Settings</h3>
			<p className="m-5 text-base font-text-l-medium">
				Most web browsers automatically accept Cookies, but you can usually
				modify your browser settings to decline or delete Cookies.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">
				2. Consent Management Platform
			</h3>
			<p className="m-5 text-base font-text-l-medium">
				We offer a consent management platform on our Services that allows you
				to manage your cookie preferences and control which Cookies are used.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">3. Do Not Track</h3>
			<p className="m-5 text-base font-text-l-medium">
				Some browsers have a &quot;Do Not Track&quot; feature that lets you tell websites
				you don&apos;t want to have your online activities tracked. However, we do
				not currently respond to Do Not Track browser signals or other similar
				mechanisms.
			</p>

			<h3 className="m-5 text-l font-text-l-medium">4. Third-Party Opt-Outs</h3>
			<p className="m-5 text-base font-text-l-medium">
				You can opt-out of targeted advertising from many ad networks, exchange
				platforms, and companies by visiting{" "}
				<a href="http://www.aboutads.info/choices">www.aboutads.info/choices</a>{" "}
				or{" "}
				<a href="http://www.networkadvertising.org/choices">
					www.networkadvertising.org/choices
				</a>
				.
			</p>

			<p className="m-5 text-base font-text-l-medium">
				If you have any questions or concerns about our use of Cookies, please
				contact us at [Insert Contact Information].
			</p>
		</article>
	);
};

export default CookiePolicy;
