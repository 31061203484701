import React from "react";
import SignUpFields from "../components/SignUpFields";
import { GetAccountInfo, RedirectToQueryParam } from "../utils/account";

const SignUpScreen = () => {
	// Check if already signed in. If so, redirect to the main screen.
	const account = GetAccountInfo()
	if (account?.id.length) {
		RedirectToQueryParam();
		return;
	}

	return (
		<div className="w-full h-screen overflow-auto flex justify-items-center items-center p-10 bg-neutral-80 text-center text-9xl-3 text-black">
			<div className="flex m-auto gap-20 items-center justify-center flex-wrap max-w-full">
				<div className="w-15">
					<img
						className="max-w-[100px] m-auto"
						loading="lazy"
						alt="Guzo Logo"
						src="/logo--guzo-2@2x.png"
					/>
					<img
						className="max-w-[200px] m-auto"
						loading="lazy"
						alt="Guzo Text"
						src="/guzo-1-11@2x.png"
					/>
				</div>
				<div className="rounded-xs-8 bg-neutral-10 box-border overflow-hidden flex flex-col items-center justify-start py-[60px] px-[50px] w-[520px] max-w-full border-[1px] border-solid border-mediumslateblue">
					<div className="flex flex-col items-center justify-center">
						<div className="self-stretch relative tracking-[-0.02em] leading-[40.11px] font-semibold inline-block">
							Sign up with Email.
						</div>
					</div>
					<SignUpFields />
				</div>
				<div className="h-10 w-[734px] hidden flex-row items-start justify-between gap-[17px] max-w-full z-[2] text-left text-base text-adventure-blue-adventure-blue-500">
					<div className="self-stretch w-[200px] rounded-3xl bg-adventure-blue-adventure-blue-50 flex flex-row items-center justify-center py-2 px-5 box-border gap-[4px]">
						<div className="self-stretch w-[42px] relative tracking-[-0.01em] leading-[24px] font-medium inline-block">
							Login
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUpScreen;
