import { React, forwardRef, useImperativeHandle } from "react";
import { createRoot } from 'react-dom/client';
import { initFlowbite } from 'flowbite'
import { Toast } from "flowbite-react";
import { HiExclamation } from "react-icons/hi";
import PropTypes from "prop-types";

const AlertToast = forwardRef((props, ref) => {
	AlertToast.displayName = 'AlertToast';

	const toastTypes = {
		error: {
			bg: "bg-red-200",
			icon: "text-red-700",
			text: "text-gray-700",
		},
		warning: {
			bg: "bg-amber-100",
			icon: "text-yellow-700",
			text: "text-gray-700",
		},
		info: {
			bg: "bg-blue-100",
			icon: "text-blue-700",
			text: "text-gray-700",
		},
	}

	let classes = (props.type in toastTypes) ? toastTypes[props.type] : toastTypes["warning"];

	useImperativeHandle(ref, () => ({
		trigger(text) {
			trigger(text)
		}

	}));

	initFlowbite();

	// create root to append toast messages.
	var root
	const trigger = (text) => {
		if (!root) {
			const rootElement = document.getElementById("toast-box");
			if (rootElement) {
				root = createRoot(rootElement);
			}
		}
		root.render(
			<Toast className={`w-[280px] max-w-full ${classes.bg}`}>
				<div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${classes.icon}`}>
					<HiExclamation className="h-5 w-5" />
				</div>
				<div className={`ml-3 text-sm font-normal ${classes.text}`}>{text}</div>
				<Toast.Toggle />
			</Toast>
		);
	};



	return (
		<div id="toast-box" className="fixed z-30 top-10 left-1/2 transform -translate-x-1/2"></div>
	)
});

AlertToast.propTypes = {
	type: PropTypes.string,
}

export default AlertToast;
