
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Check if the user is signed in and return the component if they are.
 *
 * @param {Object} component - The component to return if the user is signed in.
 * @return {Object} The component if the user is signed in, otherwise undefined.
 */
export const CheckSignedIn = () => {

	const account = GetAccountInfo();
	if (account?.id) {
		return true;
	}

	const location = useLocation();
	const currentPath = location.pathname;
	const urlEncodedPath = encodeURIComponent(currentPath);

	// Set redirect to current path.
	const navigate = useNavigate();
	navigate(`/sign-in?redirect=${urlEncodedPath}`);

	return false;
};

/**
 * Retrieves the account information from local storage and returns the specified field value if provided.
 *
 * @param {string|null} field - The field to retrieve from the account information. If null, the entire account information object is returned.
 * @return {object|string|null} - The value of the specified field, the entire account information object, or null if the account information is not found or cannot be parsed.
 */
export const GetAccountInfo = (field = null) => {
	const string = localStorage.getItem("account");
	try {
		var accountInfo = JSON.parse(string);
	} catch (e) {
		// TODO: switch logging to middleware
		console.error(e);
		return;
	}
	if (field) {
		return accountInfo[field];
	}
	return accountInfo;
}

/**
 * Redirects the user to a specified path based on the value of the "redirect" parameter in the URL.
 * If the "redirect" parameter is not present, the user is redirected to the default path.
 *
 * @param {string} [defaultPath="/discover"] - The default path to redirect to if the "redirect" parameter is not present in the URL.
 * @return {void} This function does not return a value.
 */
export const RedirectToQueryParam = (defaultPath = "/discover") => {
	const params = new URLSearchParams(window.location.search);
	const redirectPath = params.get("redirect") ? decodeURIComponent(params.get("redirect")) : defaultPath;
	window.location.href = redirectPath;
	return
}
