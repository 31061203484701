import React from "react";

const PrivacyPolicy = () => {
	return (
		<article>
			<a className="m-5 text-base font-text-l-medium" href="/signup">
				Back
			</a>
			<div>
				<h1 className="m-5 text-5xl font-text-l-medium flex justify-left">
					Privacy Policy
				</h1>
				{/* <img src="../asset/guzo_logo.png"></img> */}
			</div>
			<h2 className=" m-5 text-xl font-text-l-medium">
				Information We Collect
			</h2>
			<p className="m-5 text-base font-text-l-medium">
				We only collect personal data needed to provide our Services. This may
				include but are not limited to your name, email, phone number, search
				queries, messages, etc.
			</p>
			<h2 className="m-5 text-xl font-text-l-medium">How We Use Information</h2>
			<p className="m-5 text-base font-text-l-medium">
				We may use your personal data to:
			</p>
			<ul>
				<li className="text-base font-text-l-medium">
					Provide and maintain our Services
				</li>
				<li className="text-base font-text-l-medium">
					Notify you of changes to our Services
				</li>
				<li className="text-base font-text-l-medium">
					Develop new products and services
				</li>
				<li className="text-base font-text-l-medium">
					Personalize content and experiences
				</li>
				<li className="text-base font-text-l-medium">
					Serve targeted advertising
				</li>
				<li className="text-base font-text-l-medium">
					Comply with legal obligations
				</li>
			</ul>
			<p className="m-5 text-base font-text-l-medium">
				We may share your personal data with:
			</p>
			<ul>
				<li className="text-base font-text-l-medium">
					Service providers and partners who assist us in operating the Services
				</li>
				<li className="text-base font-text-l-medium">
					Advertisers, advertising networks, and marketing partners to serve you
					relevant ads
				</li>
				<li className="text-base font-text-l-medium">
					Other Guzo users to facilitate business networking, marketing, and
					partnerships on the platform. This may include your name, company,
					contact details, and other relevant professional data you provide.
				</li>
				<li className="text-base font-text-l-medium">
					Third parties if we sell access to aggregated demographic, interest,
					and contact data segments for targeted marketing and business
					development purposes. No individual user data will be identifiable
					through these aggregated segments.
				</li>
				<li className="text-base font-text-l-medium">
					Other parties with your consent or direction to share your data
				</li>
			</ul>
			<p className="m-5 text-base font-text-l-medium">
				Additionally, advertisers, marketing partners, and other Guzo users may
				collect information directly from you or your device such as cookies,
				device IDs, location data, etc. This data allows third parties to
				deliver, evaluate and improve advertising relevant to your interests.
			</p>
			<p className="m-5 text-base font-text-l-medium">
				You have control over the information made available through your
				account settings and can limit data sharing at any time. We do not sell
				your personal data to third parties without your explicit consent. We
				only disclose data if required by law, with your permission, or to
				operate and enhance our Services.
			</p>
			<h2 className="m-5 text-xl font-text-l-medium">User Access to Data</h2>
			<p className="m-5 text-base font-text-l-medium">
				As part of the Services, Guzo users may be able to access your name,
				company, contact details, and other information for networking and
				communication based on criteria they specify. Guzo users agree to adhere
				to our Terms of Use when accessing any data. You can control
				availability of your profile data to other users.
			</p>
		</article>
	);
};

export default PrivacyPolicy;
