import React from "react";
import "./modal.css";
import PropTypes from "prop-types";

export default function Modal({ children, cname, trigger, onClose }) {
	if (trigger) {
		return (
			<div>
				<div className="overlay-style z-30" onClick={onClose}></div>
				<div className={`modal-upload w-full h-screen max-h-max ${cname}`}>
					<div className="modal-body relative h-full max-h-max">
						<button type="button" className="absolute top-5 right-5 text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={onClose}>
							<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
							</svg>
							<span className="sr-only">Close modal</span>
						</button>
						{children}
					</div>
						
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	children: PropTypes.node,
	cname: PropTypes.string,
	trigger: PropTypes.bool,
	onClose: PropTypes.func,
}
