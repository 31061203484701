import { React, useEffect } from "react";
import {
	Routes,
	Route,
	useNavigationType,
	useLocation,
} from "react-router-dom";
import AccountSettings1 from "./pages/AccountSettings1";
import AccountSettings from "./pages/AccountSettings";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import CookiePolicy from "./pages/CookiePolicy";
import Settings from "./pages/Settings";
import SearchScreen from "./pages/SearchScreen";
import Profile from "./pages/Profile";
import SearchHistory from "./pages/SearchHistory";
import Discover from "./pages/discover";
import HomeScreen from "./pages/home";
import SignUpScreen from "./pages/SignUpScreen";
import SignInScreen from "./pages/SignInScreen";
import { accountTypeState } from "./recoil/state"
import { useRecoilState } from "recoil";
import OrgScreen from "./pages/organizationScreen";
import {
	doc, onSnapshot
} from "firebase/firestore"
import { db } from "./firebase";


function App() {
	const action = useNavigationType();
	const location = useLocation();
	const pathname = location.pathname;

	const [_currentUser, setCurrentUser] = useRecoilState(accountTypeState)
	const user = localStorage.getItem("account");
	useEffect(() => {
		setCurrentUser(JSON.parse(user))
	}, [])

	useEffect(() => {
		setCurrentUser(JSON.parse(user))
		if (JSON.parse(user)?.id?.length > 0) {
			const _unSub = onSnapshot(doc(db, "users", JSON.parse(user)?.id), (doc) => {

				setCurrentUser({ ...doc.data(), id: doc?.id })
			});


		} else {
			const userLogged = localStorage.getItem("user");
			setCurrentUser(JSON.parse(userLogged))
		}

	}, [user])


	useEffect(() => {
		if (action !== "POP") {
			window.scrollTo(0, 0);
		}
	}, [action, pathname]);

	useEffect(() => {
		let title = "";
		let metaDescription = "";

		switch (pathname) {
			case "/":
				title = "";
				metaDescription = "";
				break;
			case "/account-settings":
				title = "";
				metaDescription = "";
				break;
			case "/settings":
				title = "";
				metaDescription = "";
				break;
			case "/search":
				title = "";
				metaDescription = "";
				break;
			case "/profile":
				title = "";
				metaDescription = "";
				break;
			case "/search-history":
				title = "";
				metaDescription = "";
				break;
			case "/discover":
				title = "";
				metaDescription = "";
				break;
			case "/signup":
				title = "";
				metaDescription = "";
				break;
			case "/sign-in":
				title = "";
				metaDescription = "";
				break;
			case "/terms-of-service":
				title = "";
				metaDescription = "";
				break;
			case "/privacy-policy":
				title = "";
				metaDescription = "";
				break;
			case "/cookie-policy":
				title = "";
				metaDescription = "";
				break;
		}

		if (title) {
			document.title = title;
		}

		if (metaDescription) {
			const metaDescriptionTag = document.querySelector(
				'head > meta[name="description"]',
			);
			if (metaDescriptionTag) {
				metaDescriptionTag.content = metaDescription;
			}
		}
	}, [pathname]);

	return (
		<Routes>
			<Route path="/" element={<HomeScreen />} />
			<Route path="/bn" element={<AccountSettings1 />} />
			<Route path="/account-settings" element={<AccountSettings />} />
			<Route path="/settings" element={<AccountSettings />} />
			<Route path="/search" element={<SearchScreen />} />
			<Route path="/profile" element={<Profile />} />
			<Route path="/search-history" element={<SearchHistory />} />
			<Route path="/discover" element={<Discover />} />
			<Route path="/signup" element={<SignUpScreen />} />
			<Route path="/sign-in" element={<SignInScreen />} />
			<Route path="/org" element={<OrgScreen />} />
			<Route path="/terms-of-service" element={<TermsOfService />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/cookie-policy" element={<CookiePolicy />} />
		</Routes>
	);
}
export default App;
