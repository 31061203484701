import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from "firebase/auth";

import { auth, db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const authApi = {
	register: async function (email, password, firstName, lastName) {
		try {
			const credential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = credential.user;
			const ref = doc(db, "users", user?.uid);
			await setDoc(ref, {
				id: user?.uid,
				firstname: firstName,
				lastname: lastName,
				email: email,
				img: "",
				threads: [],
			});
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				return { id: docSnap?.id, ...docSnap?.data() };
			} else {
				console.log("No such document!");
			}
		} catch (e) {
			console.log(e);
			throw new Error(e);
		}
	},
	login: async function (email, password) {
		try {
			const response = await signInWithEmailAndPassword(auth, email, password);
			console.log(response, "response");
			const ref = doc(db, "users", response?.user?.uid);
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				return { id: docSnap?.id, ...docSnap?.data() };
			} else {
				throw new Error("You are not signed up");
			}
		} catch (e) {
			console.log(e);
			throw new Error(e);
		}
	},
	delete: async function (user) {
		try {
			await deleteDoc(doc(db, "users", user?.id));
			return true;
		} catch (e) {
			// TODO use real logging
		}
	},
};
