import { React } from "react";
import PropTypes from "prop-types";

const ProfileTile = ({
	org,
	setModalOpen,
	setModalOrg,
}) => {
	const triggerModal = () => {
		setModalOpen(true);
		setModalOrg(org);
	};

	return (
		<>
			<div className="h-[302px] w-48 rounded-3xs bg-neutral-10 box-border flex flex-col items-center justify-start p-5 gap-[15px] text-sm text-black font-text-l-medium border-[1px] border-solid border-power-black-power-black-50">
				<div className="h-full flex flex-col items-center justify-start gap-[10px]">
					<img
						className="w-[120px] h-[120px] rounded-81xl overflow-hidden shrink-0 object-cover"
						loading="lazy"
						alt={org?.name}
						src={org?.img}
					/>
					<div className="w-full grow text-center overflow-hidden text-ellipsis">{org?.name}</div>
					<button
						className="rounded-3xl bg-adventure-blue-adventure-blue-50 flex flex-row items-center justify-center py-2 px-5 gap-[4px] text-base text-adventure-blue-adventure-blue-500"
						onClick={() => triggerModal()}
					>
						Contact Info
					</button>
				</div>
			</div>
		</>
	);
};

ProfileTile.propTypes = {
	org: PropTypes.shape({
		name: PropTypes.string,
		img: PropTypes.string,
	}),
	setModalOpen: PropTypes.func,
	setModalOrg: PropTypes.func,
}

export default ProfileTile;
