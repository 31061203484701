import { React, useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../components/SidebarMenu";
import { accountTypeState, orgState } from "../recoil/state";
import { useRecoilState, useRecoilValue } from "recoil";
import { profileApi } from "../api/profile";
import { ClipLoader } from "react-spinners";
import { CheckSignedIn } from "../utils/account";

const AccountSettings = () => {
	// Check if signed in. If not redirect to sign in.
	CheckSignedIn()

	const [placeholderTextValue, setPlaceholderTextValue] = useState("");
	const [placeholderText1Value, setPlaceholderText1Value] = useState("");
	const [placeholderText2Value, setPlaceholderText2Value] = useState("");
	const [placeholderText3Value, setPlaceholderText3Value] = useState("");
	const navigate = useNavigate();

	const onMenuButtonAssemblyClick = useCallback(() => {
		navigate("/discover");
	}, [navigate]);

	const onMenuButtonAssembly1Click = useCallback(() => {
		navigate("/profile");
	}, [navigate]);

	const onMenuButtonAssembly2Click = useCallback(() => {
		navigate("/settings");
	}, [navigate]);

	const onButtonContainerClick = useCallback(() => {
		navigate("/settings");
	}, [navigate]);

	const [file, setFile] = useState();
	const [url, setUrl] = useState("");
	const [currentUser, setCurrentUser] = useRecoilState(accountTypeState);
	const [loader, setLoader] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const hiddenFileInput = useRef();

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleChange = async (e) => {
		const dir = e.target.files[0];
		console.log(dir, "dir");
		if (dir) {
			setUrl({
				src: URL.createObjectURL(dir),
			});
		}
		setFile(dir);
	};

	const create = async () => {
		setErrorMsg(null);

		try {
			setLoader(true);
			const user = await profileApi.edit(currentUser, file);

			console.log(user);
			user?.status && localStorage.clear();
			user?.status &&
				localStorage.setItem("account", JSON.stringify(user?.data));
			setCurrentUser(user?.data);
			setLoader(false);
		} catch (e) {
			setLoader(false);
			setErrorMsg(e?.message);
		}
	};

	return (
		<div className="h-full">
			<SidebarMenu
				personaImage="/persona-image1@2x.png"
				homeIcon="/home-icon.svg"
				iconsocialpersonOutline24="/iconsocialperson-outline-24px.svg"
				iconactionsettings24px="/iconactionsettings-24px.svg"
				personaImage1="/persona-image-11@2x.png"
				menuFrameHeight="unset"
				menuFrameFlex="1"
				onMenuButtonAssemblyClick={onMenuButtonAssemblyClick}
				onMenuButtonAssembly1Click={onMenuButtonAssembly1Click}
				onMenuButtonAssembly2Click={onMenuButtonAssembly2Click}
			>
				<div className="h-[104px] rounded-3xs bg-neutral-10 box-border hidden flex-col items-center justify-start gap-[10px] border-[1px] border-solid border-power-black-power-black-50">
					<div className="w-[200px] flex flex-row items-center justify-start py-2.5 px-[15px] box-border gap-[20px]">
						<div className="h-[30px] w-[30px] flex flex-col items-center justify-center">
							<img
								className="w-[19.5px] h-5 relative"
								alt=""
								src="/iconactionsettings-24px.svg"
							/>
						</div>
						<div className="h-6 flex flex-row items-center justify-start">
							<div className="self-stretch w-[63px] relative tracking-[-0.01em] leading-[24px] font-medium inline-block">
								Settings
							</div>
						</div>
					</div>
					<div className="w-[200px] flex-1 flex flex-row items-center justify-start py-2.5 pr-[49px] pl-[15px] box-border">
						<div className="self-stretch flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex-1 relative tracking-[-0.01em] leading-[24px] font-medium">
								Change Password
							</div>
						</div>
					</div>
				</div>
				<div className="rounded-3xs bg-neutral-10 hidden flex-col items-center justify-start py-2.5 px-[9px] gap-[10px] text-black border-[1px] border-solid border-power-black-power-black-50">
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start py-0 px-[9px] box-border gap-[10px]">
						<img
							className="h-10 w-10 relative rounded-81xl"
							alt=""
							src="/button.svg"
						/>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex-1 flex flex-row items-center justify-start">
								<div className="self-stretch flex-1 relative tracking-[-0.01em] leading-[22px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
									Add an Organization
								</div>
							</div>
						</div>
					</div>
				</div>
				<Link className="back-button p-2" to="/discover">
					<img
						className="w-6 h-6 inline-block"
						loading="lazy"
						alt="back button"
						src="/chevron-left-24px.svg"
					/>
					<span className="font-medium inline-block">Back</span>
				</Link>
				<section className="max-w-full w-[700px] mx-auto rounded-xs-8 bg-neutral-10 box-border shrink-0 flex flex-col items-start justify-start pt-[60px] px-[20px] pb-[20px] gap-[20px] text-center text-black font-text-l-medium border-[1px] border-solid border-mediumslateblue">
					<div className="self-stretch flex flex-col items-start gap-5 justify-center py-0 px-5">
						<h1 className="text-9xl-3 w-full text-center">Account Information</h1>
						{currentUser?.img?.length == 0 ? (
							<>
								{url?.src?.length > 0 ? (
									<div
										className="rounded-full h-44 px-1 w-44 mx-auto flex flex-col justify-center items-center -space-y-6"
										style={{ background: "rgba(242, 242, 242, 0.6)" }}
										onClick={handleClick}
									>
										<img
											src={url?.src}
											className="w-full h-full rounded-full"
										/>
										<input
											type="file"
											className="hidden"
											ref={hiddenFileInput}
											onChange={handleChange}
										/>
									</div>
								) : (
									<div
										className="rounded-full h-44 px-1 w-44 mx-auto flex flex-col justify-center items-center -space-y-6"
										style={{ background: "rgba(242, 242, 242, 0.6)" }}
										onClick={handleClick}
									>
										<h5 className="text-sm font-light">
											Upload Profile Photo*
										</h5>
										<h5 className="text-xs font-light">
											(Acceptable: jpeg, png)
										</h5>

										<input
											type="file"
											className="hidden"
											ref={hiddenFileInput}
											onChange={handleChange}
										/>
									</div>
								)}
							</>
						) : (
							<>
								{url?.src?.length > 0 ? (
									<div
										className="rounded-full h-44 px-1 w-44 mx-auto flex flex-col justify-center items-center -space-y-6"
										style={{ background: "rgba(242, 242, 242, 0.6)" }}
										onClick={handleClick}
									>
										<img
											src={url?.src}
											className="w-full h-full rounded-full"
										/>
										<input
											type="file"
											className="hidden"
											ref={hiddenFileInput}
											onChange={handleChange}
										/>
									</div>
								) : (
									<div
										className="rounded-full h-44 px-1 w-44 mx-auto flex flex-col justify-center items-center -space-y-6"
										style={{ background: "rgba(242, 242, 242, 0.6)" }}
										onClick={handleClick}
									>
										<img
											src={currentUser?.img}
											className="w-full h-full rounded-full"
										/>
										<input
											type="file"
											className="hidden"
											ref={hiddenFileInput}
											onChange={handleChange}
										/>
									</div>
								)}
							</>
						)}
						<div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-5">
							<div className="flex-1 rounded-3xs flex flex-col items-start justify-center gap-[4px]">
								<div className="">
									First Name
								</div>
								<div className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100">
									<input
										className="[border:none] [outline:none] font-medium font-text-l-medium text-sm bg-[transparent] h-5 w-52 relative tracking-[-0.01em] leading-[20px] text-adventure-blue-adventure-blue-700 text-left inline-block shrink-0 p-0"
										placeholder="First Name"
										type="text"
										value={currentUser?.firstname}
										onChange={(event) =>
											setCurrentUser({
												...currentUser,
												firstname: event.target.value,
											})
										}
									/>
								</div>
								<div className="self-stretch relative leading-[18px] text-neutral-60 hidden">
									Text helper
								</div>
							</div>
							<div className="flex-1 rounded-3xs flex flex-col items-start justify-center gap-[4px]">
								<div className="">
									Last Name
								</div>
								<div className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100">
									<input
										className="[border:none] [outline:none] font-medium font-text-l-medium text-sm bg-[transparent] h-5 w-52 relative tracking-[-0.01em] leading-[20px] text-adventure-blue-adventure-blue-700 text-left inline-block shrink-0 p-0"
										placeholder="Last Name"
										type="text"
										value={currentUser?.lastname}
										onChange={(event) =>
											setCurrentUser({
												...currentUser,
												lastname: event.target.value,
											})
										}
									/>
								</div>
								<div className="self-stretch relative leading-[18px] text-neutral-60 hidden">
									Text helper
								</div>
							</div>
						</div>
						<div className="self-stretch rounded-3xs flex flex-col items-start justify-center gap-[4px]">
							<div className="">
								Email
							</div>
							<div className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100">
								<input
									className="[border:none] [outline:none] font-medium font-text-l-medium text-sm bg-[transparent] h-5 w-52 relative tracking-[-0.01em] leading-[20px] text-adventure-blue-adventure-blue-700 text-left inline-block shrink-0 p-0"
									placeholder="Email"
									type="text"
									value={currentUser?.email}
									onChange={(event) =>
										setCurrentUser({
											...currentUser,
											email: event.target.value,
										})
									}
								/>
							</div>
							<div className="self-stretch relative leading-[18px] text-neutral-60 hidden">
								Text helper
							</div>
						</div>
						<div className="self-stretch rounded-3xs flex flex-col items-start justify-center gap-[4px]">
							<div className="hidden">
								Forget Password?
							</div>
							{/* <div className="self-stretch rounded-lg bg-neutral-601 overflow-hidden flex flex-row items-center justify-start p-1.5 border-[1px] border-solid border-neutral-100">
                  <input
                    className="[border:none] [outline:none] font-medium font-text-l-medium text-sm bg-[transparent] h-5 w-52 relative tracking-[-0.01em] leading-[20px] text-adventure-blue-adventure-blue-700 text-left inline-block shrink-0 p-0"
                    placeholder="Password"
                    type="password"
                    value={placeholderText3Value}
                    onChange={(event) =>
                      setPlaceholderText3Value(event.target.value)
                    }
                  />
                </div> */}
							<div className="self-stretch relative leading-[18px] text-neutral-60 hidden">
								Text helper
							</div>
						</div>
						<div className="self-stretch rounded-3xs flex flex-col items-start justify-center gap-[4px] text-energy-blue-energy-blue-600">
							{!loader ? (
								<button
									className="py-2 px-6 rounded-full bg-blue-200 text-blue-600"
									onClick={create}
								>
									Save
								</button>
							) : (
								<ClipLoader color={"blue"} />
							)}
							<div className="w-60 rounded-lg bg-neutral-601 box-border overflow-hidden hidden flex-row items-center justify-start py-1.5 pr-6 pl-1.5 text-sm text-adventure-blue-adventure-blue-700 border-[1px] border-solid border-neutral-100">
								<div className="h-5 flex-1 relative tracking-[-0.01em] leading-[20px] font-medium inline-block">
									Password
								</div>
							</div>
							<div className="self-stretch relative leading-[18px] text-neutral-60 hidden">
								Text helper
							</div>
						</div>
					</div>
				</section>
			</SidebarMenu>
		</div>
	);
};

export default AccountSettings;
