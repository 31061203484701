import { React, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import SidebarMenu from "../components/SidebarMenu";
import SearchInputFrame from "../components/SearchInputFrame";
import { CheckSignedIn } from "../utils/account";

const SearchHistory = () => {
	// Check if signed in. If not redirect to sign in.
	CheckSignedIn()

	const navigate = useNavigate();

	const onMenuButtonAssemblyClick = useCallback(() => {
		navigate("/discover");
	}, [navigate]);

	const onMenuButtonAssembly1Click = useCallback(() => {
		navigate("/profile");
	}, [navigate]);

	const onMenuButtonAssembly2Click = useCallback(() => {
		navigate("/settings");
	}, [navigate]);

	const onButtonContainerClick = useCallback(() => {
		navigate("/discover");
	}, [navigate]);

	return (
		<div>
			<SidebarMenu
				personaImage="/persona-image1@2x.png"
				homeIcon="/home-icon.svg"
				iconsocialpersonOutline24="/iconsocialperson-outline-24px.svg"
				iconactionsettings24px="/iconactionsettings-24px.svg"
				personaImage1="/persona-image-11@2x.png"
				onMenuButtonAssemblyClick={onMenuButtonAssemblyClick}
				onMenuButtonAssembly1Click={onMenuButtonAssembly1Click}
				onMenuButtonAssembly2Click={onMenuButtonAssembly2Click}
			>

				<div className="h-[104px] rounded-3xs bg-neutral-10 box-border hidden flex-col items-center justify-start gap-[10px] border-[1px] border-solid border-power-black-power-black-50">
					<div className="w-[200px] flex flex-row items-center justify-start py-2.5 px-[15px] box-border gap-[20px]">
						<div className="h-[30px] w-[30px] flex flex-col items-center justify-center">
							<img
								className="w-[19.5px] h-5 relative"
								alt=""
								src="/iconactionsettings-24px.svg"
							/>
						</div>
						<div className="h-6 flex flex-row items-center justify-start">
							<div className="self-stretch w-[63px] relative tracking-[-0.01em] leading-[24px] font-medium inline-block">
								Settings
							</div>
						</div>
					</div>
					<div className="w-[200px] flex-1 flex flex-row items-center justify-start py-2.5 pr-[49px] pl-[15px] box-border">
						<div className="self-stretch flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex-1 relative tracking-[-0.01em] leading-[24px] font-medium">
								Change Password
							</div>
						</div>
					</div>
				</div>
				<div className="rounded-3xs bg-neutral-10 hidden flex-col items-center justify-start py-2.5 px-[9px] gap-[10px] text-black border-[1px] border-solid border-power-black-power-black-50">
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start gap-[20px]">
						<div className="flex flex-row items-start justify-start">
							<div className="h-12 w-12 rounded-3xs bg-neutral-10 box-border flex flex-row items-center justify-center border-[0.5px] border-solid border-power-black-power-black-100">
								<div className="h-10 w-10 rounded-81xl bg-young-teal-young-teal-500 overflow-hidden shrink-0" />
							</div>
						</div>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex flex-row items-center justify-start">
								<div className="self-stretch w-[97px] relative tracking-[-0.01em] leading-[22px] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
									Profile Name
								</div>
							</div>
						</div>
					</div>
					<div className="w-[218px] flex flex-row items-center justify-start py-0 px-[9px] box-border gap-[10px]">
						<img
							className="h-10 w-10 relative rounded-81xl"
							alt=""
							src="/button.svg"
						/>
						<div className="h-[22px] flex-1 flex flex-row items-center justify-start">
							<div className="self-stretch flex-1 flex flex-row items-center justify-start">
								<div className="self-stretch flex-1 relative tracking-[-0.01em] leading-[22px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
									Add an Organization
								</div>
							</div>
						</div>
					</div>
				</div>
				<Link className="back-button p-2" to="/discover">
					<img
						className="w-6 h-6 inline-block"
						loading="lazy"
						alt="back button"
						src="/chevron-left-24px.svg"
					/>
					<span className="font-medium inline-block">Back</span>
				</Link>

				<SearchInputFrame />
			</SidebarMenu>
		</div>
	);
};

export default SearchHistory;
