import { React } from "react";
import PropTypes from "prop-types";

const TrySearchingBox = (props) => {
	let questions = [
		"How do I validate my ideas with customers?",
		"Can you point me to venture capital firms or angel investors that can provide funding?",
		"How do I price my product/offering?",
		"Can you point me to some agencies that can help me with social media and branding?",
		"Do I need to bring on a cofounder?",
		"How do I create a brand?",
	];
	let questionsHtml = questions.map((q, k) => (
		<button
			onClick={(e) => {
				e.preventDefault();
				props.sendNewMessage(q);
			}}
			key={k}
			type="submit"
			className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
		>
			{q}
		</button>
	));

	return (
		<div className="w-full flex flex-row items-start justify-center max-w-full text-dimgray">
			<div className="flex-1 rounded-3xs bg-energy-red-energy-red-50 box-border overflow-hidden flex flex-col justify-start py-5 px-[19px] max-w-full border-[1px] border-solid border-power-black-power-black-100">
				<h3 className="">Try searching:</h3>

				{questionsHtml}
			</div>
		</div>
	);
};

TrySearchingBox.propTypes = {
	sendNewMessage: PropTypes.func,
}

export default TrySearchingBox;
