import React from "react";
import PropTypes from "prop-types";
import OrgTeamBox from "./orgTeamBox";

const ContactInfoPopout = (props) => {
	const org = props.org;
	const orgInfoFields = {
		About: org.about,
		Email: org.email,
		Phone: org.phone,
		Address: org.address,
		Website: org.website,
		Location: org.location,
	};

	let team = org.team || [];

	return (
		<div className="rounded-3xs bg-neutral-10 h-full max-h-max flex flex-col items-center justify-start pt-[25px] px-0 pb-0 box-border gap-[30px] tracking-[normal] mq450:h-auto">
			<main className="self-stretch h-full overflow-auto max-h-max flex flex-col items-start justify-start py-0 px-[30px] box-border max-w-full shrink-0">
				<section className="self-stretch flex flex-col items-start justify-start max-w-full text-left text-xl text-black font-text-l-medium">
					<div className="self-stretch flex flex-col items-center justify-start max-w-full">
						<img
							className="w-[150px] h-[150px] rounded-81xl overflow-hidden shrink-0 object-cover"
							loading="lazy"
							alt={org.name}
							src={org.img}
						/>
						<div className="self-stretch flex flex-row items-start justify-center max-w-full">
							<div className="flex-1 flex flex-col items-center justify-start max-w-full">
								<div className="flex flex-col items-start justify-start">
									<div className="flex flex-row items-center justify-center">
										<h3 className="m-0 h-7 w-[50px] relative text-inherit tracking-[-0.02em] leading-[28px] font-semibold font-inherit inline-block mq450:text-base mq450:leading-[22px]">
											{org.name}
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="self-stretch bg-neutral-10 flex flex-col items-start justify-start py-5 box-border gap-[20px] max-w-full">
						<div className="self-stretch flex flex-row items-start justify-start max-w-full">
							<div className="flex-1 flex flex-col items-start justify-center max-w-full">
								<div className="flex flex-col items-start justify-start">
									<div className="flex flex-row items-center justify-center">
										<h3 className="m-0 h-7 w-[161px] relative text-inherit tracking-[-0.02em] leading-[28px] font-semibold font-inherit inline-block mq450:text-base mq450:leading-[22px]">
											Organization Info
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="org-info w-full text-xs text-neutral-60">
							{Object.entries(orgInfoFields).map(([key, value]) => {
								if (!value) {
									return null;
								}
								return (
									<div key={key} className="org-field-container mb-5">
										<div className="self-stretch">
											<div className="org-field key self-stretch relative text-sm tracking-[-0.01em] leading-[20px] font-medium font-text-l-medium text-neutral-90 text-left inline-block">
												{key}
											</div>
											<div className="self-stretch flex flex-row items-center justify-between p-1.5 gap-[20px] mq450:flex-wrap">
												<div className="relative text-sm tracking-[-0.01em] leading-[20px] font-medium font-text-l-medium text-power-black-power-black-500 text-left inline-block shrink-0">
													<p className="m-0">{value}</p>
												</div>
												<button className="cursor-pointer [border:none] py-2 px-5 bg-adventure-blue-adventure-blue-500 h-10 rounded-3xl flex flex-row items-center justify-center box-border gap-[4px]">
													<div className="w-[41px] relative text-base leading-[24px] font-medium font-text-l-medium text-neutral-neutral-100 text-left inline-block">
														Copy
													</div>
												</button>
											</div>
											<div className="self-stretch relative text-xs leading-[18px] font-text-l-medium text-neutral-60 text-left hidden" />
										</div>
									</div>
								);
							})}
						</div>

						{/* The Team START */}
						<div className="self-stretch flex flex-row items-start justify-start max-w-full">
							<div className="flex-1 flex flex-col items-start justify-center max-w-full">
								<div className="flex flex-col items-start justify-start">
									<div className="flex flex-row items-center justify-center">
										<h3 className="m-0 h-7 w-[93px] relative text-inherit tracking-[-0.02em] leading-[28px] font-semibold font-inherit inline-block mq450:text-base mq450:leading-[22px]">
											The Team
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="w-[480px] flex flex-row items-start justify-start gap-[20px] max-w-full text-xs text-neutral-60 mq450:flex-wrap">


							<OrgTeamBox team={team} />


						</div>
					</div>
				</section>
			</main>
			<footer className="self-stretch flex flex-row items-start justify-center pt-0 px-[30px] pb-[60px] text-left text-base text-adventure-blue-adventure-blue-500 font-text-l-medium">
				<div
					className="h-10 w-[171px] rounded-3xl bg-adventure-blue-adventure-blue-50 flex flex-row items-center justify-center py-2 px-5 box-border gap-[4px] cursor-pointer"
					onClick={() => props.setModalOpen(false)}
				>
					<div className="h-6 w-11 relative leading-[24px] font-medium inline-block">
						Close
					</div>
				</div>
			</footer>
		</div >
	);
};

ContactInfoPopout.propTypes = {
	setModalOpen: PropTypes.func,
	org: PropTypes.shape({
		name: PropTypes.string,
		img: PropTypes.string,
		about: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
		address: PropTypes.string,
		website: PropTypes.string,
		location: PropTypes.string,
		team: PropTypes.string,
	}).isRequired,
};

export default ContactInfoPopout;
