import React from "react";

const TermsOfService = () => {
	return (
		<article>
			<a className="m-5 text-base font-text-l-medium" href="/signup">
				Back
			</a>
			<h1 className="m-5 text-5xl font-text-l-medium flex justify-left">
				Terms of Service
			</h1>
			<p className="m-5 text-base font-text-l-medium">
				Welcome to Guzo! By using our website, mobile applications, and related
				services (collectively &quot;Services&quot;), you agree to abide by these Terms of
				Service. Please read them carefully.
			</p>
			<h2 className=" m-5 text-xl font-text-l-medium">Using Our Services</h2>
			<p className="m-5 text-base font-text-l-medium">
				You must be at least 13 years old to use our Services. Minors may only
				use Guzo under the supervision of an adult.
			</p>
			<p className="m-5 text-base font-text-l-medium">
				By using our Services, you agree not to:
			</p>
			<ul>
				<li className="text-base font-text-l-medium">
					Violate any laws, regulations, or these Terms of Service
				</li>
				<li className="text-base font-text-l-medium">
					Infringe on others&apos; intellectual property or privacy
				</li>
				<li className="text-base font-text-l-medium">
					Post false, inaccurate, misleading, defamatory, or harassing content
				</li>
				<li className="text-base font-text-l-medium">
					Distribute viruses, malware, or corrupted content
				</li>
			</ul>
			<p className="m-5 text-base font-text-l-medium">
				We reserve the right to terminate any account or restrict access to our
				Services for violations of these Terms of Service, applicable law, or
				any other reason.
			</p>
			<p className="m-5 text-base font-text-l-medium">
				You are responsible for maintaining the confidentiality of your account
				credentials and restricting access to your account. You agree to
				immediately notify Guzo of any unauthorized use of your account.
			</p>
			<h2 className=" m-5 text-xl font-text-l-medium">User Content</h2>
			<p className="m-5 text-base font-text-l-medium">
				Any content you post through Guzo remains yours. By sharing content, you
				grant us a non-exclusive, royalty-free license to use it. We may need to
				disclose content if required by law. We respect your privacy. Please see
				our Privacy Policy for details.
			</p>
			<h2 className=" m-5 text-xl font-text-l-medium">Intellectual Property</h2>
			<p className="m-5 text-base font-text-l-medium">
				Guzo retains full intellectual property rights for our Services and
				their contents. You may not copy, distribute, or create derivative works
				from Guzo content without our written consent.
			</p>
			<h2 className=" m-5 text-xl font-text-l-medium">
				Disclaimers and Limitation of Liability
			</h2>
			<p className="m-5 text-base font-text-l-medium">
				Guzo provides services on an &quot;as is&quot; basis, without warranties. We
				disclaim any liability for damages arising from use of our Services to
				the fullest extent permitted by law.
			</p>
			<h2 className=" m-5 text-xl font-text-l-medium">Compliance with Laws</h2>
			<p className="m-5 text-base font-text-l-medium">
				You agree to comply with all applicable laws and regulations when
				accessing or using our Services. You are responsible for ensuring your
				use of Guzo complies with laws where you reside or access our Services.
			</p>
		</article>
	);
};

export default TermsOfService;
