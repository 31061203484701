import { React, useState, useEffect, useRef } from "react";
import ContactInfoPopout from "./ContactInfoPopout";
import ProfileTile from "./ProfileTile";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { db } from "../firebase";
import { useRecoilValue } from "recoil";
import { accountTypeState } from "../recoil/state";
import guzoBubble from "../asset/images/guzo-chat-bubble.png";
import BeatLoader from "react-spinners/BeatLoader";
import Modal from "./modal";
import { messageApi } from "../api/message";
import PropTypes from "prop-types";

const PersonaNamesFrame = ({ msgs }) => {
	const currentUser = useRecoilValue(accountTypeState);

	const chatRef = useRef(null);

	useEffect(() => {
		if (chatRef.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight;
		}
	}, [msgs]);

	return (
		<div className="flex flex-col grow w-full overflow-y-scroll space-y-4 px-5" ref={chatRef} >
			<div className="w-full max-w-2xl mx-auto">
				{Object.entries(msgs).map(([key, msg]) => {
					return (
						<div key={key} className="w-full flex flex-col space-y-4">
							<div
								className={`${msg?.role === "user" ? "w-full flex justify-end" : "w-full flex justify-start"}`}
							>
								{msg?.role == "user" ? (
									<img
										src={currentUser?.img}
										className="h-10 rounded-full w-10"
									/>
								) : (
									<img src={guzoBubble} className="h-10 rounded-full w-10" />
								)}
							</div>

							<div
								className="w-full py-2 px-4 rounded-lg border border-solid border-neutral-50"
								style={{ background: "#ECEBFE" }}
							>
								<div className="response-text whitespace-pre-wrap leading-[20px] font-medium inline-block ">
									{msg?.text}
								</div>
							</div>
							{msg.role == "assistant" && <Orgs msg={msg} />}
						</div>
					);
				})}
			</div>
		</div>
	);
};

PersonaNamesFrame.propTypes = {
	msgs: PropTypes.array,
};

export default PersonaNamesFrame;

const Orgs = ({ msg }) => {
	const [orgs, setOrgs] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalOrg, setModalOrg] = useState(null);
	let tagArray = [];
	useEffect(() => {
		async function getKeywordData() {
			// Call Api to get orgs
			if (msg.keywords) {
				textArray = msg.keywords.map((innerArray) => innerArray[0]);
			} else if (!msg.keywords && msg.role === "assistant") {
				try {
					const resp = await messageApi.getKeywords(msg.text);
					if (
						resp?.data &&
						resp.data?.success === true &&
						resp.data?.message?.data
					) {
						let keywords = resp.data?.message?.data;
						tagArray = keywords.map((keywordObj) => keywordObj[0].toLowerCase());
						console.log("keyword response", resp);
						console.log("Processed tags", tagArray);
					} else {
						// TODO: Add proper logging.
						console.log("Something went wrong getting keywords");
					}
				} catch (error) {
					// TODO Add proper logging
					console.log(error);
				}
			}

			const q = query(collection(db, "organizations"), where(
				"tags", "array-contains-any", tagArray
			));
			onSnapshot(q, (querySnapshot) => {
				const matchingOrgs = [];
				querySnapshot.forEach((doc) => {
					matchingOrgs.push({ ...doc.data(), id: doc.id });
				});

				setOrgs(matchingOrgs);
			});
		}
		getKeywordData();
	}, []);
	console.log("orgs", orgs);
	return (
		<>
			<div>
				{orgs === null && (
					<div className="w-full flex justify-center">
						<BeatLoader size={12} color="blue" />
					</div>
				)}
				<div className="flex flex-row flex-wrap justify-center gap-4 gap-y-8 w-full">
					{orgs?.map((org) => {
						return (
							<ProfileTile
								key={org.id}
								org={org}
								setModalOpen={setModalOpen}
								setModalOrg={setModalOrg}
							/>
						);
					})}
				</div>
			</div>
			<Modal
				trigger={modalOpen}
				org={modalOrg}
				cname="max-w-xl py-2 px-8 rounded-lg "
				onClose={() => setModalOpen(false)}
			>
				<ContactInfoPopout org={modalOrg} setModalOpen={setModalOpen} />
			</Modal>
		</>
	);
};


Orgs.propTypes = {
	msg: PropTypes.shape({
		keywords: PropTypes.array,
		role: PropTypes.string,
		text: PropTypes.string,
	}),
};
