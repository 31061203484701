import { React } from "react";

const HomeScreen = () => {

	return (
		<div className="h-screen flex justify-items-center items-center p-10">
			<div className="w-full">
				<div className="logo-box w-full flex flex-col justify-items-center items-center">
					<img
						className="object-cover max-w-30% m-auto"
						loading="lazy"
						alt="Guzo Logo"
						src="/logo--guzo-2@2x.png"
					/>
					<img
						className="object-cover max-w-60% m-auto"
						loading="lazy"
						alt="Guzo Text"
						src="/guzo-1-11@2x.png"
					/>
				</div>
				<div className="CTA-box text-center max-w-3xl m-auto">
					<div className="my-10 text-xl sm:text-3xl md:text-3xl font-extrabold">
						Connect with resources and grow your business
						with your new AI assistant.
					</div>
					<div className="CTA-btn">
						<a href="/signup" className="inline-block bg-primary hover:bg-primary-hover text-white font-bold w-48 py-2 px-4 rounded-full my-2">Sign Up</a>
					</div>
					<div className="CTA-btn">
						<a href="/sign-in" className="inline-block bg-neutral-100 hover:bg-neutral-200 text-primary font-bold w-48 py-2 px-4 rounded-full my-2">Login</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeScreen;
