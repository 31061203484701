import React from "react";
import PropTypes from "prop-types";

const OrgTeamBox = (props) => {

	if (!props.team) {
		return (
			<div className="team-members">
				<div className="text-center text-black">No team members</div>
			</div>
		)
	}

	return (
		<div className="team-members">
			{Object.entries(props.team).map(([key, profile]) => {
				let name = `${profile.firstname} ${profile.lastname}`;

				return (
					<div key={key} className="team-member flex-1 rounded-3xs bg-neutral-10 flex flex-col items-center justify-start py-[15px] px-5 gap-[30px] border-[1px] border-solid border-power-black-power-black-50">
						<div className="self-stretch flex flex-col items-center justify-start gap-[10px] text-center text-sm text-black">
							<img className="w-[120px] h-[120px] rounded-81xl overflow-hidden shrink-0 object-cover" loading="lazy" alt={name} src={profile.img || "/iconsocialperson-outline-24px.svg"} />
							<div className="name- container">
								<div className="leading-[20px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
									{name}
								</div>
							</div>
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-sm text-neutral-90">
							<div className="self-stretch h-5 relative tracking-[-0.01em] leading-[20px] font-medium inline-block">
								Role
							</div>
							<div className="self-stretch rounded-lg bg-neutral-10 overflow-hidden flex flex-row items-center justify-start p-1.5 text-power-black-power-black-500 border-[1px] border-solid border-power-black-power-black-50">
								<div className="h-5 w-52 relative tracking-[-0.01em] leading-[20px] font-medium inline-block shrink-0">
									{profile.role}
								</div>
							</div>
							<div className="self-stretch h-[18px] relative text-xs leading-[18px] text-neutral-60 hidden" />
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
							<div className="self-stretch h-[18px] relative leading-[18px] hidden" />
							<div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-sm text-neutral-90">
								<div className="self-stretch h-5 relative tracking-[-0.01em] leading-[20px] font-medium inline-block">
									Email
								</div>
								<div className="self-stretch rounded-lg bg-neutral-10 overflow-hidden flex flex-row items-center justify-start p-1.5 text-power-black-power-black-500 border-[1px] border-solid border-power-black-power-black-50">
									<div className="h-5 w-52 relative tracking-[-0.01em] leading-[20px] font-medium inline-block shrink-0 whitespace-nowrap">
										{profile.email}
									</div>
								</div>
							</div>
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
							<div className="self-stretch h-[18px] relative leading-[18px] hidden" />
							<div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-sm text-neutral-90">
								<div className="self-stretch h-5 relative tracking-[-0.01em] leading-[20px] font-medium inline-block">
									Phone
								</div>
								<div className="self-stretch rounded-lg bg-neutral-10 overflow-hidden flex flex-row items-center justify-start p-1.5 whitespace-nowrap text-power-black-power-black-500 border-[1px] border-solid border-power-black-power-black-50">
									<div className="h-5 w-52 relative tracking-[-0.01em] leading-[20px] font-medium inline-block shrink-0">
										{profile.phone}
									</div>
								</div>
							</div>
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
							<div className="self-stretch h-[18px] relative leading-[18px] hidden" />
							<div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-sm text-neutral-90">
								<div className="self-stretch h-5 relative tracking-[-0.01em] leading-[20px] font-medium inline-block">
									LinkedIn
								</div>
								<div className="self-stretch rounded-lg bg-neutral-10 overflow-hidden flex flex-row items-center justify-start p-1.5 whitespace-nowrap text-power-black-power-black-500 border-[1px] border-solid border-power-black-power-black-50">
									<div className="h-5 w-52 relative tracking-[-0.01em] leading-[20px] font-medium inline-block shrink-0">
										<span>{profile.linkedin}</span>
										<span className="text-neutral-60">{` `}</span>
									</div>
								</div>
								<div className="self-stretch h-[18px] relative text-xs leading-[18px] text-neutral-60 hidden" />
							</div>
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
							<div className="self-stretch h-[18px] relative leading-[18px] hidden" />
							<div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-sm text-neutral-90">
								<div className="self-stretch h-5 relative tracking-[-0.01em] leading-[20px] font-medium inline-block">{`Scheduling `}</div>
								<div className="self-stretch rounded-lg bg-neutral-10 overflow-hidden flex flex-row items-center justify-start p-1.5 text-power-black-power-black-500 border-[1px] border-solid border-power-black-power-black-50">
									<div className="h-5 w-52 relative tracking-[-0.01em] leading-[20px] font-medium inline-block shrink-0">
										{profile.scheduling}
									</div>
								</div>
								<div className="self-stretch h-[18px] relative text-xs leading-[18px] text-neutral-60 hidden" />
							</div>
						</div>
					</div>

				)
			})}

		</div>
	)
}

OrgTeamBox.propTypes = {
	team: PropTypes.shape({
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		img: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
	}).isRequired,
};

export default OrgTeamBox

