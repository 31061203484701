import { React, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SidebarMenu from "../components/SidebarMenu";
import PersonaNamesFrame from "../components/PersonaNamesFrame";
import { useRecoilValue } from "recoil";
import { accountTypeState } from "../recoil/state";
import BeatLoader from "react-spinners/BeatLoader";
import { useLocation, useParams } from "react-router-dom";
import { messageApi } from "../api/message";
import { CheckSignedIn } from "../utils/account";

const SearchScreen = (props) => {
	// Check if signed in. If not redirect to sign in.
	CheckSignedIn()

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const threadId = searchParams.get("threadId") || location?.state?.threadId;

	const currentUser = useRecoilValue(accountTypeState);
	const [currentChat, setCurrentChat] = useState();
	const [conversations, setConversations] = useState([]);
	const [loader, setLoading] = useState(false);
	const [messagesLoader, setMessagesLoading] = useState(false);

	const onMenuButtonAssemblyClick = useCallback(() => {
		navigate("/discover");
	}, [navigate]);

	const onMenuButtonAssembly1Click = useCallback(() => {
		navigate("/profile");
	}, [navigate]);

	const onMenuButtonAssembly2Click = useCallback(() => {
		navigate("/settings");
	}, [navigate]);

	const onButtonContainerClick = useCallback(() => {
		navigate("/discover");
	}, [navigate]);

	const [isLoading, setLoader] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [msgs, setMsg] = useState([]);

	useEffect(() => {
		// Call API to get all conversations
		const getConversation = async () => {
			try {
				setMessagesLoading(true);
				const res = await messageApi.getMessages(threadId);
				setMessagesLoading(false);
				setMsg(res);
			} catch (error) {
				// TODO: Use real logging
			}
		};

		if (threadId?.length > 0) {
			getConversation();
		}
	}, [threadId]);

	const send = async () => {
		if (newMessage?.length == 0) {
			return;
		}

		setLoading(true);

		const message = {
			sender: currentUser?.id,
			text: newMessage,
			conversationid: currentChat?.id,
			date: Number(Date.now()),
			time: new Date(),
		};

		try {
			const res = await messageApi.sendMessages(threadId, newMessage);
			setMsg((prev) => [...prev, ...res]);
			setNewMessage("");

			//  const docRef = await addDoc(collection(db, "messages"),message);

			//   const docSnap = await getDoc(docRef);
			//   docSnap?.exists()&& setNewMessage("")
			//   docSnap?.exists()&& setLoading(false)
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	return (
		<div className="h-full justify-center">
			<SidebarMenu
				personaImage="/persona-image1@2x.png"
				homeIcon="/home-icon.svg"
				iconsocialpersonOutline24="/iconsocialperson-outline-24px.svg"
				iconactionsettings24px="/iconactionsettings-24px.svg"
				personaImage1="/persona-image-11@2x.png"
				onMenuButtonAssemblyClick={onMenuButtonAssemblyClick}
				onMenuButtonAssembly1Click={onMenuButtonAssembly1Click}
				onMenuButtonAssembly2Click={onMenuButtonAssembly2Click}
			>
				<section className="h-full self-stretch flex flex-col max-w-full gap-[20px] justify-center">
					<Link className="back-button p-2" to="/discover">
						<img
							className="w-6 h-6 inline-block"
							loading="lazy"
							alt="back button"
							src="/chevron-left-24px.svg"
						/>
						<span className="font-medium inline-block">Back</span>
					</Link>

					{messagesLoader ? (
						<div className="w-full h-full flex flex-col items-center absolute h-screen justify-center">
							<BeatLoader />
						</div>
					) : (
						<PersonaNamesFrame
							currentChat={currentChat}
							state={threadId}
							msgs={msgs}
							setMsg={setMsg}
						/>
					)}
					<div className="flex-row items-start justify-center p-2">
						<div className="w-full max-w-[600px] m-auto rounded-3xl bg-neutral-10 box-border flex flex-row flex-wrap items-end justify-end py-2 px-[15px] gap-[8px] max-w-full border-[1px] border-solid border-neutral-50">
							<div className="flex-1 flex flex-col items-start justify-start pt-2 px-0 pb-0 box-border max-w-full">
								<textarea
									className="self-stretch py-3 relative leading-[20px] font-medium inline-block outline-none"
									placeholder=" Search Guzo...."
									value={newMessage}
									onChange={(e) => setNewMessage(e.target.value)}
								/>
							</div>
							<div
								className="h-9 rounded-3xl bg-adventure-blue-adventure-blue-500 flex flex-row items-start justify-start py-1.5 px-5 box-border gap-[4px] text-base text-neutral-neutral-100"
								onClick={() => !loader && send()}
							>
								<div className="self-stretch w-0 relative leading-[24px] font-medium inline-block" />
								<div className="flex flex-col items-start justify-start pt-1 px-0 pb-0 text-center">
									{!loader ? (
										<img
											className="w-4 h-4 relative"
											loading="lazy"
											alt=""
											src="/send-24px.svg"
										/>
									) : (
										<BeatLoader color="white" size={8} />
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</SidebarMenu >
		</div >
	);
};

export default SearchScreen;
