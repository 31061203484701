import { React, useRef, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { messageApi } from "../api/message";
import { accountTypeState } from "../recoil/state";
import { useRecoilValue } from "recoil";
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from "react-router-dom";
import TrySearchingBox from "./TrySearchingBox";

const SearchBox = () => {
	const [loader, setLoader] = useState(false);
	const [msgs, setMsg] = useState([]);
	const [newMessage, setNewMessage] = useState("");
	const childRef = useRef();

	const currentUser = useRecoilValue(accountTypeState);
	const navigate = useNavigate();

	const sendNewMessage = async (newMessage) => {
		if (!newMessage || newMessage?.length == 0) {
			// TODO send alert to user
			return;
		}
		setLoader(true);
		try {
			const res = await messageApi.startConversation(currentUser);
			if (!res) {
				console.log("Error, no response.");
				return;
			}
			const threadId = res;
			const resp2 = await messageApi.sendMessages(threadId, newMessage);
			if (!resp2) {
				// TODO Log to logger
				console.log("Error, no response.");
			}
			navigate("/search", { state: { threadId: threadId } });
			setLoader(false);
		} catch (e) {
			setLoader(false);
			console.log(e);
		}
	};

	const onButtonContainerClick = useCallback(() => {
		navigate("/search-history");
	}, [navigate]);

	function timeAgo(dateString) {
		const date = new Date(dateString);
		const now = new Date();

		const seconds = Math.floor((now - date) / 1000);
		let interval = Math.floor(seconds / 31536000);

		if (interval >= 1) {
			return interval === 1 ? interval + " year ago" : interval + " years ago";
		}
		interval = Math.floor(seconds / 2592000);
		if (interval >= 1) {
			return interval === 1
				? interval + " month ago"
				: interval + " months ago";
		}
		interval = Math.floor(seconds / 86400);
		if (interval >= 1) {
			return interval === 1 ? interval + " day ago" : interval + " days ago";
		}
		interval = Math.floor(seconds / 3600);
		if (interval >= 1) {
			return interval === 1 ? interval + " hour ago" : interval + " hours ago";
		}
		interval = Math.floor(seconds / 60);
		if (interval >= 1) {
			return interval === 1
				? interval + " minute ago"
				: interval + " minutes ago";
		}
		return Math.floor(seconds) === 1
			? Math.floor(seconds) + " second ago"
			: Math.floor(seconds) + " seconds ago";
	}

	useEffect(() => {
		const getConversations = async () => {
			try {
				if (currentUser?.threads?.length > 0) {

					const promises =
						currentUser?.threads?.map(async (thread) => {
							const res = await messageApi.getInitialQuestion(thread);
							if (res.length == 0) {
								return;
							}
							const textsArray = res?.map((message) => message.text) ?? [];
							console.log(res, "res messages");
							return {
								texts: textsArray,
								timePeriod: timeAgo(res[0]?.createdAt),
								thread: thread,
							};
						}) ?? [];

					const results = await Promise.all(promises);

					setMsg(results.filter((res) => res).reverse());
				}
			} catch (error) {
				console.log(error);
			}
		};

		if (currentUser?.id?.length > 0) {
			getConversations();
		}
	}, [currentUser?.threads]);

	const string = localStorage.getItem("account");
	var name;
	try {
		var arr = JSON.parse(string);
		name = arr["firstname"];
	} catch (e) {
		// TODO: switch logging to middleware
		console.error(e);
		name = "Friend";
	}

	return (
		<div className="max-w-full w-[700px] m-auto overflow-y-auto shrink-0 flex flex-col items-center justify-start pt-[30px] px-2.5 pb-[52px] box-border gap-[20px] text-center text-sm text-neutral-60 font-text-l-medium mq450:pt-5 mq450:pb-[34px] mq450:box-border">
			<img
				className="h-5 relative shrink-0 object-cover"
				loading="lazy"
				alt=""
				src="/guzo-1-1@2x.png"
			/>
			<h1 className="relative text-17xl leading-9 text-center font-semibold text-black inline-block max-w-full">
				Welcome back, {name}
			</h1>
			<div className="search-input rounded-3xl bg-neutral-10 box-border flex flex-row flex-wrap items-center justify-start py-2 px-[15px] gap-[8px] w-full max-w-full border-[1px] border-solid border-neutral-50">
				<input
					type="text"
					placeholder="How can Guzo connect you today..."
					className="focus:outline-none appearance-none h-10 flex-1 relative leading-[24px] font-medium inline-block in-w-[193px] max-w-full border-0 border-none outline-none focus:border-none focus:border-transparent"
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
				/>
				<button
					className="cursor-pointer [border:none] py-2 px-5 bg-adventure-blue-adventure-blue-500 h-10 rounded-3xl flex flex-row items-center justify-center box-border gap-[4px]"
					onClick={() => !loader && sendNewMessage(newMessage)}
				>
					<div className="h-6 w-36 relative text-base leading-[24px] font-medium font-text-l-medium text-neutral-neutral-100 inline-block">
						{!loader ? (
							"Start a new search"
						) : (
							<BeatLoader color="white" size={10} />
						)}
					</div>
					{!loader && (
						<img className="h-4 w-4 relative" alt="" src="/send-24px.svg" />
					)}
				</button>
			</div>
			<TrySearchingBox reference={childRef} sendNewMessage={sendNewMessage} />
			{msgs?.length > 0 ? (
				<div className="search-history w-full self-stretch flex flex-row items-center justify-between gap-[20px] text-center text-xl text-black-text">
					<div className="flex flex-row items-start justify-start">
						<div className="h-7 flex-1 relative tracking-[-0.02em] leading-[28px] font-semibold inline-block">
							Search History
						</div>
					</div>
					<div
						className="h-10 rounded-lg flex flex-row items-center justify-center py-2 px-5 box-border gap-[4px] cursor-pointer text-left text-base text-adventure-blue-adventure-blue-500"
						onClick={onButtonContainerClick}
					></div>
				</div>
			) : null}

			<div className="flex flex-col w-full space-y-8">
				{Object.entries(msgs).map(([key, msg]) => {
					return (
						<div key={key} className="flex flex-row">
							<div className="flex flex-row w-full">
								{Object.entries(msg?.texts?.slice(0, 3)).map(([key, text]) => {
									return (
										<Link
											key={key}
											className="w-full py-2 px-4 rounded-3xl  border border-solid border-neutral-50"
											style={{ background: "#ECEBFE" }}
											to={{
												pathname: `/search`,
												search: `?threadId=${msg?.thread}`,
											}}>
											<div className="response-text font-semibold ">{text}</div>
											<h5 className="text-[12px] font-semibold text-black">{msg?.timePeriod}</h5>
										</Link>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SearchBox;
